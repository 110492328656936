<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h3 class="page-title font-weight-bold mb-0">勤怠詳細</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="text-heading-2 font-weight-medium">
            {{ dayjs(date).format('YYYY年 M月 D日') }}/
            {{ user.full_name }} さんの勤怠報告
          </div>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="6">
          <span>{{ allWorktimesDetails.length }} 件 あります。</span>
        </v-col>
        <v-col class="text-right">
          <v-btn
            height="30"
            class="btn-new"
            color="#CFD3FE"
            depressed
            min-width="100"
            to="/work-time/create"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            新しい勤怠を追加する
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-for="worktime in workTimes" :key="worktime.id">
          <v-card
            color="#F2F6F8"
            v-if="!worktime.isEditing"
            class="work-report-sec pa-5"
          >
            <v-row class="sec-1">
              <v-col cols="8">
                <div class="report-task-id ">勤怠ID: W{{ worktime.id }}</div>
                <div class="report-task-id mt-4">
                  <router-link
                    :to="{
                      name: 'TaskView',
                      params: { id: worktime.task_details.id }
                    }"
                    target="_blank"
                  >
                    タスクID: {{ worktime.task_details.id }}
                  </router-link>
                  /

                  <router-link
                    :to="{
                      name: 'CustomerViewDetailBasic',
                      params: {
                        customer_id: worktime.task_details.customer.id,
                        site_id: worktime.task_details.site.id
                      }
                    }"
                    target="_blank"
                  >
                    {{ worktime.task_details.site.name }}（{{
                      worktime.task_details.customer.name
                    }}）
                  </router-link>
                </div>

                <div class="inner-sec">
                  <div class="work-type sec">
                    タイプ
                    <span class="val-sec">{{
                      clockType(worktime.type_id)
                    }}</span>
                  </div>
                  <div class="in-out sec d-flex">
                    <div class="in-time mr-10">
                      イン
                      <span class="val-sec">
                        {{ worktime.clock_in }}
                      </span>
                    </div>
                    <div class="out-time">
                      アウト
                      <span class="val-sec">
                        {{ worktime.clock_out }}
                      </span>
                    </div>
                  </div>
                  <div class="rest-time sec d-flex">
                    <div class="day-time mr-10">
                      昼休憩
                      <span class="val-sec">
                        {{ formatRestTime(worktime.rest_hours_day) }}
                      </span>
                    </div>
                    <div class="night-time mr-10">
                      夜休憩<span class="val-sec">
                        {{ formatRestTime(worktime.rest_hours_night) }}
                      </span>
                    </div>
                    <div class="tag-time">
                      <span v-for="tag in worktime.tags" :key="tag.id">
                        <v-icon size="14">$tag</v-icon> {{ tag.name }}
                      </span>
                    </div>
                  </div>
                  <div class="wt-memo sec">
                    <v-row>
                      <v-col cols="12" class="in-time"
                        >メモ<span class="val-sec">{{
                          worktime.memo
                        }}</span></v-col
                      >
                    </v-row>
                  </div>
                </div>
              </v-col>
              <v-col cols="4" class="txt-right">
                <v-btn rounded color="white" @click="toggleEdit(worktime)">
                  編集
                  <v-icon right size="20">
                    $write
                  </v-icon>
                </v-btn>

                <div class="created_by">
                  <p class="created_date">
                    <v-icon color="#9D9D9D" size="14">$clock</v-icon>
                    <span>{{
                      dayjs(worktime.updated_at).format('YYYY/MM/DD HH:mm')
                    }}</span>
                  </p>
                  <p class="created_user">
                    <v-icon color="#9D9D9D" size="12">$user</v-icon>
                    <span>{{ worktime.user_details.full_name }}</span>
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row class="sec-2">
              <v-col cols="6" v-if="worktime.work_hours_day != '0.0'">
                <v-icon>$sun</v-icon>
                <span
                  >昼計 <b>{{ formatTime(worktime.work_hours_day) }}</b></span
                >
              </v-col>
              <v-col cols="6" v-if="worktime.work_hours_night != '0.0'">
                <v-icon>$moon</v-icon>
                <span
                  >夜計 <b>{{ formatTime(worktime.work_hours_night) }}</b></span
                >
              </v-col>
            </v-row>
          </v-card>
          <WorkTimeEdit v-else :worktime.sync="worktime"></WorkTimeEdit>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import dayjs from '@/plugins/dayjs'
import WorkTimeEdit from '@/components/admin/partials/WorkTime/WorkTimeEdit.vue'

export default {
  name: 'Detail',
  components: { WorkTimeEdit },
  props: ['date', 'user_id'],

  data() {
    return {
      dayjs: dayjs,
      isHidden: false,
      workTimes: [],
      user: []
    }
  },

  methods: {
    async getWorkTimes() {
      let params = {}
      params.date = this.date
      params.user_id = this.user_id
      await this.$store.dispatch('DETAIL_WORKTIMES', params).then(
        response => {
          if (response.data.status == 'success') {
            this.user = response.data.data.user
          }
        },
        error => {
          throw error
        }
      )
    },

    clockType(type_id) {
      let type = ''
      switch (type_id) {
        case 0:
          type = '現場勤務（リ）'
          break
        case 1:
          type = '現場勤務（個）'
          break
        case 2:
          type = '運転'
          break
        case 3:
          type = '参加'
          break
        case 4:
          type = 'タスク'
          break
        case 5:
          type = 'その他'
          break

        default:
          break
      }
      return type
    },
    toggleEdit(worktime) {
      worktime.isEditing = !worktime.isEditing
    },
    formatTime(time) {
      let newTime = time.split(':')
      let hour = parseInt(newTime[0])
      let minute = parseInt(newTime[1])
      return hour + ' 時間 ' + minute + ' 分'
    },
    formatRestTime(time) {
      let newTime = time.split(':')
      let hour = parseInt(newTime[0])
      let minute = parseInt(newTime[1])
      if (hour == '0' && (minute == '0' || minute == '00')) return '-'
      else if (hour == '0') return minute + '分'
      else if (minute == '0') return hour + '時間'
      else return hour + '時間' + minute + '分'
    }
  },

  watch: {
    allWorktimesDetails(newVal) {
      this.workTimes = newVal.map(worktime => {
        return {
          ...worktime,
          isEditing: false
        }
      })
    }
  },

  computed: {
    allWorktimesDetails() {
      return this.$store.getters.allWorktimesDetails
    }
  },

  created() {
    this.getWorkTimes()
  }
}
</script>

<style src="./Detail.scss" lang="scss" scoped></style>
