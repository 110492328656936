<template>
  <div class="attendance-container py-10 mt-8">
    <validation-observer ref="observer">
      <v-form @submit.prevent="update" ref="form">
        <v-row>
          <v-col cols="10">
            <v-row align="center">
              <v-col cols="12" class="text-heading-3 pl-12">
                勤怠ID: W{{ workTimeData.id }}
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="2" class="form-header-1 text-right">タイプ</v-col>
              <v-col cols="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="type_id"
                  rules="required"
                >
                  <v-select
                    class="select"
                    placeholder="未選択"
                    outlined
                    dense
                    :items="clockType"
                    item-text="name"
                    item-value="value"
                    v-model="workTimeData.type_id"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    :hide-details="errors.length === 0"
                  >
                  </v-select>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="2" class="form-header-1 text-right">イン</v-col>
              <v-col cols="3">
                <v-dialog
                  ref="modalIn"
                  v-model="modalIn"
                  :return-value.sync="workTimeData.clock_in"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                      v-slot="{ errors }"
                      name="clock_in"
                      rules="required"
                    >
                      <v-text-field
                        v-model="workTimeData.clock_in"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="select"
                        :error-messages="errors"
                        :error="errors.length !== 0"
                        :hide-details="errors.length === 0"
                      ></v-text-field>
                    </validation-provider>
                  </template>
                  <v-time-picker
                    v-if="modalIn"
                    v-model="workTimeData.clock_in"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modalIn = false">
                      キャンセル
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.modalIn.save(workTimeData.clock_in)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="1" class="form-header-1 text-right">アウト</v-col>
              <v-col cols="3">
                <v-dialog
                  ref="modalOut"
                  v-model="modalOut"
                  :return-value.sync="workTimeData.clock_out"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                      v-slot="{ errors }"
                      name="clock_out"
                      rules="required"
                    >
                      <v-text-field
                        v-model="workTimeData.clock_out"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="select"
                        :error-messages="errors"
                        :error="errors.length !== 0"
                        :hide-details="errors.length === 0"
                      ></v-text-field>
                    </validation-provider>
                  </template>
                  <v-time-picker
                    v-if="modalOut"
                    v-model="workTimeData.clock_out"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modalOut = false">
                      キャンセル
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.modalOut.save(workTimeData.clock_out)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="2" class="form-header-1 text-right">昼休憩</v-col>
              <v-col cols="2" class="d-flex align-center">
                <v-text-field
                  class="select"
                  dense
                  outlined
                  hide-details
                  v-model="workTimeData.rest_hours_day"
                  @keypress="isNumber"
                  autocomplete="off"
                >
                </v-text-field>
                <span class="ml-4">分</span>
              </v-col>

              <v-col cols="1" class="form-header-1 text-right"
                >夜休憩</v-col
              >
              <v-col cols="2" class="d-flex align-center">
                <v-text-field
                  class="select"
                  dense
                  outlined
                  hide-details
                  v-model="workTimeData.rest_hours_night"
                  @keypress="isNumber"
                  autocomplete="off"
                >
                </v-text-field>
                <span class="ml-4">分</span>
              </v-col>

              <v-col cols="2" class="form-header-1 text-right"
                >WTタグ</v-col
              >
              <v-col cols="3" class="d-flex flex-wrap align-center">
                <div class="mr-3" v-for="tag in tags" :key="tag.id">
                  <v-checkbox
                    class="mt-0"
                    dense
                    hide-details
                    :label="tag.name"
                    :value="tag"
                    v-model="_worktimeTags"
                  >
                  </v-checkbox>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2" class="form-header-1 text-right">メモ</v-col>
              <v-col cols="9" class="flex-grow-1">
                <v-textarea
                  outlined
                  hide-details
                  dense
                  class="select"
                  label="勤怠メモを入力"
                  v-model="workTimeData.memo"
                >
                </v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="ml-12 mt-5">
                <span class="btn" @click="deleteWorkTime">
                  <v-icon color="#AD4545" size="14">$trash </v-icon>
                  削除する
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="2" class="txt-right">
            <v-btn
              rounded
              color="#4F55A7"
              class="white--text"
              @click="update"
              :loading="loading"
            >
              更新
              <v-icon size="16" class="pl-1 pt-1">
                $refresh
              </v-icon>
            </v-btn>
            <div class="pt-3">
              <v-btn rounded color="white" @click="goBack">
                キャンセル
              </v-btn>
            </div>
            <div class="created_by">
              <p class="created_date">
                <v-icon color="#9D9D9D" size="14">$clock</v-icon>
                <span>
                  {{ dayjs(worktime.updated_at).format('YYYY/MM/DD HH:mm') }}
                </span>
              </p>
              <p class="created_user">
                <v-icon color="#9D9D9D" size="12">$user</v-icon>
                <span>{{ worktime.user_details.full_name }}</span>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import dayjs from '@/plugins/dayjs'
import { mapMutations } from 'vuex'
export default {
  name: 'WorkTimeEdit',
  props: ['worktime'],
  data() {
    return {
      workTimeData: JSON.parse(JSON.stringify(this.worktime)),
      dayjs: dayjs,
      date: '',
      modal: false,
      modalIn: false,
      modalOut: false,
      clockType: [
        { header: '現場 ' },
        { name: '現場勤務（リ）', value: 0 },
        { name: '現場勤務（個）', value: 1 },
        { divider: true },
        { header: 'その他' },
        { name: '運転 & 調整', value: 2 },
        { name: '参加', value: 3 },
        { name: 'タスク', value: 4 },
        { name: 'その他', value: 5 }
      ],
      tags: [
        { id: 1, name: '遅刻' },
        { id: 2, name: '早退' },
        { id: 3, name: 'その他' }
      ],
      loading: false
    }
  },
  methods: {
    ...mapMutations(['showModal']),
    isNumber(val) {
      if (isNaN(Number(val.key))) {
        return val.preventDefault()
      }
    },
    update() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }

        let params = {}
        params.type_id = this.workTimeData.type_id
        params.clock_in = this.workTimeData.clock_in
        params.clock_out = this.workTimeData.clock_out
        params.day_rest = this.workTimeData.rest_hours_day
        params.night_rest = this.workTimeData.rest_hours_night
        params.memo = this.workTimeData.memo
        params.tags = this._worktimeTags.map(function(a) {
          return a.id
        })

        let id = this.workTimeData.id

        this.loading = true

        this.$store
          .dispatch('UPDATE_WORKTIME', { id, params })
          .then(
            response => {
              if (response.data.status == 'success') {
                let worktimeApiData = response.data.data.data
                worktimeApiData.isEditing = false
                for (const [key, value] of Object.entries(worktimeApiData)) {
                  this._workTime[key] = value
                }
              }
            },
            error => {
              throw error
            }
          )
          .finally(() => {
            this.loading = false
          })
      })
    },

    goBack() {
      this._workTime.isEditing = false
    },

    deleteWorkTime() {
      this.showModal({
        text: 'このアクションを実施してよろしいですか',
        action: {
          process: {
            color: 'red',
            text: '実施する'
          },
          cancel: {
            color: 'secondary',
            text: 'キャンセル'
          }
        },
        func: this.remove
      })
    },

    remove() {
      this.$store
        .dispatch('DELETE_WORKTIME', { id: this.workTimeData.id })
        .then(
          response => {
            if (response.data.status == 'success') {
              this.$store.commit('DELETE_WORKTIME', this.workTimeData.id)
              this._workTime.isEditing = false
            }
          },
          error => {
            throw error
          }
        )
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    _worktimeTags: {
      get() {
        return this.workTimeData.tags.map(tag => {
          return {
            id: tag.id,
            name: tag.name
          }
        })
      },
      set(newVal) {
        this.workTimeData.tags = newVal
      }
    },
    _workTime: {
      get() {
        return this.worktime
      },
      set(newVal) {
        this.$emit('update:worktime', newVal)
      }
    },
  },
  created() {
    let newTime = this.workTimeData.rest_hours_day.split(':')
    let hour = parseInt(newTime[0])
    let minute = parseInt(newTime[1])
    if (hour == 0 && (minute == 0)) this.workTimeData.rest_hours_day = 0
    else if (hour == 0) this.workTimeData.rest_hours_day = minute
    else if (minute == 0) this.workTimeData.rest_hours_day = hour * 60
    else this.workTimeData.rest_hours_day = hour * 60 + minute

    newTime = this.workTimeData.rest_hours_night.split(':')
    hour = parseInt(newTime[0])
    minute = parseInt(newTime[1])
    if (hour == 0 && (minute == 0)) this.workTimeData.rest_hours_night = 0
    else if (hour == 0) this.workTimeData.rest_hours_night = minute
    else if (minute == 0) this.workTimeData.rest_hours_night = hour * 60
    else this.workTimeData.rest_hours_night = hour * 60 + minute
  },
}
</script>
<style src="@/views/work-time/Detail.scss" lang="scss" scoped></style>
