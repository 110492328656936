var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "attendance-container py-10 mt-8"
  }, [_c('validation-observer', {
    ref: "observer"
  }, [_c('v-form', {
    ref: "form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-heading-3 pl-12",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 勤怠ID: W" + _vm._s(_vm.workTimeData.id) + " ")])], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header-1 text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("タイプ")]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "type_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          staticClass: "select",
          attrs: {
            "placeholder": "未選択",
            "outlined": "",
            "dense": "",
            "items": _vm.clockType,
            "item-text": "name",
            "item-value": "value",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0
          },
          model: {
            value: _vm.workTimeData.type_id,
            callback: function callback($$v) {
              _vm.$set(_vm.workTimeData, "type_id", $$v);
            },
            expression: "workTimeData.type_id"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header-1 text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("イン")]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-dialog', {
    ref: "modalIn",
    attrs: {
      "return-value": _vm.workTimeData.clock_in,
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.workTimeData, "clock_in", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.workTimeData, "clock_in", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('validation-provider', {
          attrs: {
            "name": "clock_in",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('v-text-field', _vm._g(_vm._b({
                staticClass: "select",
                attrs: {
                  "dense": "",
                  "outlined": "",
                  "readonly": "",
                  "error-messages": errors,
                  "error": errors.length !== 0,
                  "hide-details": errors.length === 0
                },
                model: {
                  value: _vm.workTimeData.clock_in,
                  callback: function callback($$v) {
                    _vm.$set(_vm.workTimeData, "clock_in", $$v);
                  },
                  expression: "workTimeData.clock_in"
                }
              }, 'v-text-field', attrs, false), on))];
            }
          }], null, true)
        })];
      }
    }]),
    model: {
      value: _vm.modalIn,
      callback: function callback($$v) {
        _vm.modalIn = $$v;
      },
      expression: "modalIn"
    }
  }, [_vm.modalIn ? _c('v-time-picker', {
    attrs: {
      "full-width": ""
    },
    model: {
      value: _vm.workTimeData.clock_in,
      callback: function callback($$v) {
        _vm.$set(_vm.workTimeData, "clock_in", $$v);
      },
      expression: "workTimeData.clock_in"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.modalIn = false;
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.modalIn.save(_vm.workTimeData.clock_in);
      }
    }
  }, [_vm._v(" OK ")])], 1) : _vm._e()], 1)], 1), _c('v-col', {
    staticClass: "form-header-1 text-right",
    attrs: {
      "cols": "1"
    }
  }, [_vm._v("アウト")]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-dialog', {
    ref: "modalOut",
    attrs: {
      "return-value": _vm.workTimeData.clock_out,
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.workTimeData, "clock_out", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.workTimeData, "clock_out", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on,
            attrs = _ref4.attrs;
        return [_c('validation-provider', {
          attrs: {
            "name": "clock_out",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('v-text-field', _vm._g(_vm._b({
                staticClass: "select",
                attrs: {
                  "dense": "",
                  "outlined": "",
                  "readonly": "",
                  "error-messages": errors,
                  "error": errors.length !== 0,
                  "hide-details": errors.length === 0
                },
                model: {
                  value: _vm.workTimeData.clock_out,
                  callback: function callback($$v) {
                    _vm.$set(_vm.workTimeData, "clock_out", $$v);
                  },
                  expression: "workTimeData.clock_out"
                }
              }, 'v-text-field', attrs, false), on))];
            }
          }], null, true)
        })];
      }
    }]),
    model: {
      value: _vm.modalOut,
      callback: function callback($$v) {
        _vm.modalOut = $$v;
      },
      expression: "modalOut"
    }
  }, [_vm.modalOut ? _c('v-time-picker', {
    attrs: {
      "full-width": ""
    },
    model: {
      value: _vm.workTimeData.clock_out,
      callback: function callback($$v) {
        _vm.$set(_vm.workTimeData, "clock_out", $$v);
      },
      expression: "workTimeData.clock_out"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.modalOut = false;
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.modalOut.save(_vm.workTimeData.clock_out);
      }
    }
  }, [_vm._v(" OK ")])], 1) : _vm._e()], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header-1 text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("昼休憩")]), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    staticClass: "select",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "autocomplete": "off"
    },
    on: {
      "keypress": _vm.isNumber
    },
    model: {
      value: _vm.workTimeData.rest_hours_day,
      callback: function callback($$v) {
        _vm.$set(_vm.workTimeData, "rest_hours_day", $$v);
      },
      expression: "workTimeData.rest_hours_day"
    }
  }), _c('span', {
    staticClass: "ml-4"
  }, [_vm._v("分")])], 1), _c('v-col', {
    staticClass: "form-header-1 text-right",
    attrs: {
      "cols": "1"
    }
  }, [_vm._v("夜休憩")]), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    staticClass: "select",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "autocomplete": "off"
    },
    on: {
      "keypress": _vm.isNumber
    },
    model: {
      value: _vm.workTimeData.rest_hours_night,
      callback: function callback($$v) {
        _vm.$set(_vm.workTimeData, "rest_hours_night", $$v);
      },
      expression: "workTimeData.rest_hours_night"
    }
  }), _c('span', {
    staticClass: "ml-4"
  }, [_vm._v("分")])], 1), _c('v-col', {
    staticClass: "form-header-1 text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("WTタグ")]), _c('v-col', {
    staticClass: "d-flex flex-wrap align-center",
    attrs: {
      "cols": "3"
    }
  }, _vm._l(_vm.tags, function (tag) {
    return _c('div', {
      key: tag.id,
      staticClass: "mr-3"
    }, [_c('v-checkbox', {
      staticClass: "mt-0",
      attrs: {
        "dense": "",
        "hide-details": "",
        "label": tag.name,
        "value": tag
      },
      model: {
        value: _vm._worktimeTags,
        callback: function callback($$v) {
          _vm._worktimeTags = $$v;
        },
        expression: "_worktimeTags"
      }
    })], 1);
  }), 0)], 1), _c('v-row', [_c('v-col', {
    staticClass: "form-header-1 text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("メモ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-textarea', {
    staticClass: "select",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "dense": "",
      "label": "勤怠メモを入力"
    },
    model: {
      value: _vm.workTimeData.memo,
      callback: function callback($$v) {
        _vm.$set(_vm.workTimeData, "memo", $$v);
      },
      expression: "workTimeData.memo"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "ml-12 mt-5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "btn",
    on: {
      "click": _vm.deleteWorkTime
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "#AD4545",
      "size": "14"
    }
  }, [_vm._v("$trash ")]), _vm._v(" 削除する ")], 1)])], 1)], 1), _c('v-col', {
    staticClass: "txt-right",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "rounded": "",
      "color": "#4F55A7",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v(" 更新 "), _c('v-icon', {
    staticClass: "pl-1 pt-1",
    attrs: {
      "size": "16"
    }
  }, [_vm._v(" $refresh ")])], 1), _c('div', {
    staticClass: "pt-3"
  }, [_c('v-btn', {
    attrs: {
      "rounded": "",
      "color": "white"
    },
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" キャンセル ")])], 1), _c('div', {
    staticClass: "created_by"
  }, [_c('p', {
    staticClass: "created_date"
  }, [_c('v-icon', {
    attrs: {
      "color": "#9D9D9D",
      "size": "14"
    }
  }, [_vm._v("$clock")]), _c('span', [_vm._v(" " + _vm._s(_vm.dayjs(_vm.worktime.updated_at).format('YYYY/MM/DD HH:mm')) + " ")])], 1), _c('p', {
    staticClass: "created_user"
  }, [_c('v-icon', {
    attrs: {
      "color": "#9D9D9D",
      "size": "12"
    }
  }, [_vm._v("$user")]), _c('span', [_vm._v(_vm._s(_vm.worktime.user_details.full_name))])], 1)])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }