var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-9 pa-md-6 pa-lg-9"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "page-title font-weight-bold mb-0"
  }, [_vm._v("勤怠詳細")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-heading-2 font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.dayjs(_vm.date).format('YYYY年 M月 D日')) + "/ " + _vm._s(_vm.user.full_name) + " さんの勤怠報告 ")])])], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.allWorktimesDetails.length) + " 件 あります。")])]), _c('v-col', {
    staticClass: "text-right"
  }, [_c('v-btn', {
    staticClass: "btn-new",
    attrs: {
      "height": "30",
      "color": "#CFD3FE",
      "depressed": "",
      "min-width": "100",
      "to": "/work-time/create"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-plus ")]), _vm._v(" 新しい勤怠を追加する ")], 1)], 1)], 1), _c('v-row', _vm._l(_vm.workTimes, function (worktime) {
    return _c('v-col', {
      key: worktime.id,
      attrs: {
        "cols": "12"
      }
    }, [!worktime.isEditing ? _c('v-card', {
      staticClass: "work-report-sec pa-5",
      attrs: {
        "color": "#F2F6F8"
      }
    }, [_c('v-row', {
      staticClass: "sec-1"
    }, [_c('v-col', {
      attrs: {
        "cols": "8"
      }
    }, [_c('div', {
      staticClass: "report-task-id "
    }, [_vm._v("勤怠ID: W" + _vm._s(worktime.id))]), _c('div', {
      staticClass: "report-task-id mt-4"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'TaskView',
          params: {
            id: worktime.task_details.id
          }
        },
        "target": "_blank"
      }
    }, [_vm._v(" タスクID: " + _vm._s(worktime.task_details.id) + " ")]), _vm._v(" / "), _c('router-link', {
      attrs: {
        "to": {
          name: 'CustomerViewDetailBasic',
          params: {
            customer_id: worktime.task_details.customer.id,
            site_id: worktime.task_details.site.id
          }
        },
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(worktime.task_details.site.name) + "（" + _vm._s(worktime.task_details.customer.name) + "） ")])], 1), _c('div', {
      staticClass: "inner-sec"
    }, [_c('div', {
      staticClass: "work-type sec"
    }, [_vm._v(" タイプ "), _c('span', {
      staticClass: "val-sec"
    }, [_vm._v(_vm._s(_vm.clockType(worktime.type_id)))])]), _c('div', {
      staticClass: "in-out sec d-flex"
    }, [_c('div', {
      staticClass: "in-time mr-10"
    }, [_vm._v(" イン "), _c('span', {
      staticClass: "val-sec"
    }, [_vm._v(" " + _vm._s(worktime.clock_in) + " ")])]), _c('div', {
      staticClass: "out-time"
    }, [_vm._v(" アウト "), _c('span', {
      staticClass: "val-sec"
    }, [_vm._v(" " + _vm._s(worktime.clock_out) + " ")])])]), _c('div', {
      staticClass: "rest-time sec d-flex"
    }, [_c('div', {
      staticClass: "day-time mr-10"
    }, [_vm._v(" 昼休憩 "), _c('span', {
      staticClass: "val-sec"
    }, [_vm._v(" " + _vm._s(_vm.formatRestTime(worktime.rest_hours_day)) + " ")])]), _c('div', {
      staticClass: "night-time mr-10"
    }, [_vm._v(" 夜休憩"), _c('span', {
      staticClass: "val-sec"
    }, [_vm._v(" " + _vm._s(_vm.formatRestTime(worktime.rest_hours_night)) + " ")])]), _c('div', {
      staticClass: "tag-time"
    }, _vm._l(worktime.tags, function (tag) {
      return _c('span', {
        key: tag.id
      }, [_c('v-icon', {
        attrs: {
          "size": "14"
        }
      }, [_vm._v("$tag")]), _vm._v(" " + _vm._s(tag.name) + " ")], 1);
    }), 0)]), _c('div', {
      staticClass: "wt-memo sec"
    }, [_c('v-row', [_c('v-col', {
      staticClass: "in-time",
      attrs: {
        "cols": "12"
      }
    }, [_vm._v("メモ"), _c('span', {
      staticClass: "val-sec"
    }, [_vm._v(_vm._s(worktime.memo))])])], 1)], 1)])]), _c('v-col', {
      staticClass: "txt-right",
      attrs: {
        "cols": "4"
      }
    }, [_c('v-btn', {
      attrs: {
        "rounded": "",
        "color": "white"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleEdit(worktime);
        }
      }
    }, [_vm._v(" 編集 "), _c('v-icon', {
      attrs: {
        "right": "",
        "size": "20"
      }
    }, [_vm._v(" $write ")])], 1), _c('div', {
      staticClass: "created_by"
    }, [_c('p', {
      staticClass: "created_date"
    }, [_c('v-icon', {
      attrs: {
        "color": "#9D9D9D",
        "size": "14"
      }
    }, [_vm._v("$clock")]), _c('span', [_vm._v(_vm._s(_vm.dayjs(worktime.updated_at).format('YYYY/MM/DD HH:mm')))])], 1), _c('p', {
      staticClass: "created_user"
    }, [_c('v-icon', {
      attrs: {
        "color": "#9D9D9D",
        "size": "12"
      }
    }, [_vm._v("$user")]), _c('span', [_vm._v(_vm._s(worktime.user_details.full_name))])], 1)])], 1)], 1), _c('v-divider', {
      staticClass: "my-3"
    }), _c('v-row', {
      staticClass: "sec-2"
    }, [worktime.work_hours_day != '0.0' ? _c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-icon', [_vm._v("$sun")]), _c('span', [_vm._v("昼計 "), _c('b', [_vm._v(_vm._s(_vm.formatTime(worktime.work_hours_day)))])])], 1) : _vm._e(), worktime.work_hours_night != '0.0' ? _c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-icon', [_vm._v("$moon")]), _c('span', [_vm._v("夜計 "), _c('b', [_vm._v(_vm._s(_vm.formatTime(worktime.work_hours_night)))])])], 1) : _vm._e()], 1)], 1) : _c('WorkTimeEdit', {
      attrs: {
        "worktime": worktime
      },
      on: {
        "update:worktime": function updateWorktime($event) {
          worktime = $event;
        }
      }
    })], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }